<template>
  <div class="ripple-box" :style="`width: ${size}px; height: ${size}px;`">
    <div class="ripple-container" :style="`width: ${size}px; height: ${size}px; background-color: ${color}`">
    </div>
    <div class="ripple" @click="pointClick" :style="`width: ${size}px; height: ${size}px; background-color: ${color}`"></div>
  </div>
 
</template>
<script>
export default {
  name: 'Ripple',
  props: {
    size: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: '#ffffff'
    },
  },
  methods: {
    pointClick(){
      this.$emit('child-event')
    }
  }
}
</script>
<style>
.ripple-container {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  animation: ripple-animation 1s linear infinite;
}
.ripple{
  position: absolute;
  transform: translate(-50%, -50%) scale(0.5);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  box-shadow: 0 0 5px #fff;
}

@keyframes ripple-animation {
  0% {
    transform:  scale(0.2);
    opacity: 0.2;
  }
  30% {
    transform:  scale(0.4);
    opacity: 0.3;
  }
  50% {
    transform:  scale(0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}
</style>