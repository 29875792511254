<template>
    <div
      class="drag-and-zoom"
      ref="container"
      :style="{ transform: `translate(${x}px, ${y}px) scale(${scale})` }"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      @wheel="handleWheel"
    >
      <img v-if="imgUrl.url" class="mapImg" ref="mapImg"  alt="map" :src="imgUrl.url"/>
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DragAndZoom',
    props: {
      scaleMax: {
        type: Number,
        default: 5
      },
      scaleMin: {
        type: Number,
        default: 1
      },
      imgUrl: {
        type: Object,
      }
    },
    data() {
      return {
        x: 0,
        y: 0,
        scale: 1,
        lastDistance: 0,
        isDragging: false,
        startX: 0,
        startY: 0,
      }
    },
    watch:{
      scale(newVal, oldVal) {
          if(newVal > 2) {
            this.$emit('child-scale', 0.5)
          }
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize)
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleWindowResize)
    },
    methods: {
      // 滚轮事件
      handleWheel(e){
        const delta = e.deltaY * -0.01;
        const newScale = this.scale + delta;
        if (newScale < this.scaleMin || newScale > this.scaleMax) return;

        const touchX = e.clientX;
        const touchY = e.clientY;
        const containerRect = this.$refs.container.getBoundingClientRect();
        const containerX = containerRect.left;
        const containerY = containerRect.top;
        const offsetX = touchX - containerX;
        const offsetY = touchY - containerY;
        const newX = offsetX * (1 - newScale / this.scale);
        const newY = offsetY * (1 - newScale / this.scale);

        this.x += newX;
        this.y += newY;
        this.containerEdge()
        this.scale = newScale;
      },
      // 放大
      enlarge(){
       this.largeOrSmall(1)
      },
      // 缩小
      ensmall(){
        this.largeOrSmall(-1)
      },
      // 放大或缩小
      largeOrSmall(flag){
        const newScale = this.scale + this.scale * flag * 0.08;
        if (newScale < this.scaleMin || newScale > this.scaleMax) return;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const touchX = screenWidth / 2;
        const touchY = screenHeight / 2;
        const containerRect = this.$refs.container.getBoundingClientRect();
        const containerX = containerRect.left;
        const containerY = containerRect.top;
        const offsetX = touchX - containerX;
        const offsetY = touchY - containerY;
        const newX = offsetX * (1 - newScale / this.scale);
        const newY = offsetY * (1 - newScale / this.scale);
        this.x += newX;
        this.y += newY;
        this.containerEdge()
        this.scale = newScale;
      },

      handleTouchStart(e) {
        // e.preventDefault()
        const touch = e.touches[0] || e.changedTouches[0]
        const { clientX, clientY } = touch
        if (e.touches.length === 2) {
          const x2 = e.touches[1].clientX
          const y2 = e.touches[1].clientY
          this.lastDistance = Math.sqrt((clientX - x2) ** 2 + (clientY - y2) ** 2)
        } else {
          this.isDragging = true
          this.startX = clientX
          this.startY = clientY
        }
      },
      handleTouchMove(e) {
        e.preventDefault()
        if (e.touches.length === 2) {
            const x1 = e.touches[0].clientX
            const y1 = e.touches[0].clientY
            const x2 = e.touches[1].clientX
            const y2 = e.touches[1].clientY
            const distance = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)
            // const delta = (distance - this.lastDistance) / 500
            const delta = Math.pow(distance / this.lastDistance, 1.5) - 1;
            const centerX = (x1 + x2) / 2
            const centerY = (y1 + y2) / 2
            const containerRect = this.$el.getBoundingClientRect()
            const containerX = containerRect.left
            const containerY = containerRect.top
            // const containerWidth = containerRect.width
            // const containerHeight = containerRect.height
            const offsetX = centerX - containerX
            const offsetY = centerY - containerY
            const scaleRatio = this.scale + delta
            const newScale = Math.min(Math.max(scaleRatio, this.scaleMin), this.scaleMax)
            const xDiff = offsetX * (1 - newScale / this.scale)
            const yDiff = offsetY * (1 - newScale / this.scale)
            this.x += xDiff
            this.y += yDiff
            this.scale = newScale
            this.lastDistance = distance
            this.containerEdge()
        } else {
          if (!this.isDragging) {
            return
          }
          const touch = e.touches[0] || e.changedTouches[0]
          const x = touch.clientX - this.startX
          const y = touch.clientY - this.startY
          this.x += x
          this.y += y
          this.startX = touch.clientX
          this.startY = touch.clientY
          this.containerEdge()
        }
      },
      handleTouchEnd(e) {
        // e.preventDefault()
        this.isDragging = false
      },
      handleWindowResize() {
        this.x = 0
        this.y = 0
        this.scale = 1
      },
      // 判断容器边界
      containerEdge(){
            const containerRect = this.$refs.container.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;
            const maxLeft = 0;
            const maxTop = 0;
            const maxRight = screenWidth;
            const maxBottom = screenHeight;

            let newX = this.x;
            let newY = this.y;
            // 容器x,y坐标一定是负数，保证上边界和左边界在屏幕外
            if (newX > maxLeft) {
                newX = maxLeft;
            }
            if (newY > maxTop) {
                newY = maxTop;
            }
            // 容器x坐标 + 容器宽度 > 屏幕宽度，容器y坐标 + 容器高度 > 屏幕高度
            if (newX + containerWidth < maxRight) {
                newX = maxRight - containerWidth;
            }
            if (newY + containerHeight < maxBottom) {
                newY = maxBottom - containerHeight;
            }
            this.x = newX
            this.y = newY
      }
    }
  }
  </script>
<style>
.drag-and-zoom{
    transform-origin: left top;
}
</style>