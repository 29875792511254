<template>
        <div class="container">
            <van-loading class="img-loading" v-if="showProgress" type="spinner" size="40" vertical>图片较大，请耐心等待加载...</van-loading>
            <!-- <van-progress :percentage="50" stroke-width="8" /> -->
            <van-progress class="img-progress" v-if="showProgress" color="linear-gradient(45deg, #00DBDE, #FC00FF)" :percentage="progress" stroke-width="12" />

            <dragZoom
                :scaleMax=10
                :scaleMin=1
                ref="dragZoom"
                @child-scale = "changePointScale"
                :imgUrl="imgUrl"
            >

                <div  v-show="showBg" class="point-container" ref="pointContainer" v-for="(item, index) in points" :key="index"
                 :style="'left:'+item.position[0]+'px;top:'+item.position[1]+'px;transform: scale('+pointScale+')'" >
                    <div v-if="showPoints.includes(item.status)">
                        <Ripple :color="item.color" :size="15" @child-event="pointClick(item)"/>
                    </div>
                </div>
            </dragZoom>
            
            <van-image  v-if="showBg" class="plus_btn" :src="require('../assets/imgs/plus.jpeg')" @click="enlarge"></van-image>
            <van-image  v-if="showBg" class="sub_btn" :src="require('../assets/imgs/sub.jpeg')" @click="ensmall"></van-image>
            <div class="tooltip-box" v-if="showTooltip" @click="closeTooltip">
                <div class="tooltip"  :style="`border: 1px solid ${tooltipContent.color};`">
                    <!-- <div class="close-tooltip" @click="closeTooltip">x</div> -->
                    <div class="site-tooltip-content">
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/dc06ee8d35ebbc7008ec922263625bdb.png`" alt="">
                            项目名称：</span>
                        <span class="title-value">{{tooltipContent.name}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/02594230de781314528ce3708df642cb.png`" alt="">
                            总投资：</span>
                        <span class="title-value">{{tooltipContent.input_money}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/c26b5683e03b29ce21c7fca3afcb7a5f.png`" alt="">
                            投资者：</span>
                        <span class="title-value">{{tooltipContent.investor}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/06a8f9dd043460d347db2e4f8a245d13.png`" alt="">
                            属地乡镇：</span>
                        <span class="title-value">{{tooltipContent.unit}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/f71fff149e47c4010f6dfb9e851b899d.png`" alt="">
                            项目状态：</span>
                        <span class="title-value">{{tooltipContent.status_text}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/9a579d415a041eefff06011aa3734f24.png`" alt="">
                            项目备注：</span>
                        <span class="title-value">{{tooltipContent.remark}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/4756702b3d0525dca9bcc09f7a3d2529.png`" alt="">
                            建设地址：</span>
                        <span class="title-value">{{tooltipContent.address}}</span>
                    </div>
                    <div class="site-tooltip-content" >
                        <span class="title-text">
                        <img class="title-icon" :src="`${baseUrl}/uploads/20230322/ca9ea6b6a56391e459c2b23f616375c7.png`" alt="">
                            建设内容：</span>
                    </div>
                    <span ref="contentRef" class="title-value tooltip-html" v-html="tooltipContent.content"></span>
                </div>
            </div>
           
            <div class="point-legend" v-if="showBg">
                <div class="point-li" v-for="(item, index) in pointColor" :key="index"
                 @click="showPoint(index+1)"
                 >
                    <div class="point-li-icon" :style="`background:${showPoints.includes(index + 1) ? item.color : '#666'};`"></div>
                    <div class="point-li-text" :style="`color: ${showPoints.includes(index + 1) ? 'black' : '#666'};`">{{ item.name }}</div>
                </div>
            </div>
            <div class="address-legend" v-if="showBg">
                <div class="collapse-header" @click="toggleCollapse">
                    {{collapseTitle}}
                    <!-- 隐藏箭头的 CSS 样式 -->
                    <div class="arrow" :class="{ 'down': isCollapse }"></div>
                </div>
                <div class="collapse-content" :class="{ 'show': isCollapse }">
                    <div class="address-module" v-for="(item, index) in addressModule" :key="index">
                        <div class="address-btn" :style="`${item.type == 'block' ? 'background: '+item.color : 'border:4px ' + item.color + ' ' + item.type};`"></div>
                        <div class="address-text">{{ item.name }}</div>
                    </div>
                </div>
               
            </div>
            
            <van-dialog v-model="showDialog" title="密码" show-cancel-button @confirm="confirmBtn" @cancel="cancelBtn">
                <van-field class="psssword-input" type="password" v-model="pass" placeholder="请输入密码" />
            </van-dialog>
        </div>
   
</template>
<script>
import dragZoom from "@/components/common/dragZoom.vue";
import Ripple from "@/components/common/ripples.vue"
import { verifyPass, projectPageList } from '../api';

export default {
    name: "Home",
    components: {
        dragZoom, Ripple
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_API,
            collapseTitle: '图例',
            private_map: "",
            points: [], // 点数组
            showPoints: [1, 2],
            lastDistance: 0,
            isDragging: false,
            startX: 0,
            startY: 0,
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            showDialog: true,
            showBg: false,
            pass: "",
            pointColor: [
                {name: "在谈项目",color: '#0000ff'},
                {name: "已签约项目",color: '#ffff00'},
                {name: "落地后停滞项目",color: '#ffa500'},
                {name: "已落地项目",color: '#008000'},
            ],
            // 地块
            addressModule: [
                {name: '招商组团', color: 'blue', type: 'dashed'},
                {name: '城镇开发边界集中建设区', color: 'pink', type: 'solid'},
                {name: '已供闲置地块', color: 'yellow', type: 'block'},
                {name: '招商存量地块', color: 'green', type: 'block'},
                {name: '招商增量地块', color: 'red', type: 'block'},
                {name: '城市更新范围', color: 'orange', type: 'block'},
            ],
            showTooltip: false,
            tooltipContent: {},
            isCollapse: false,
            progress: 0,
            showProgress: false,
            pointScale: 1,
            imgUrl: {url: ""}
        };
    },
    updated(){
        if(this.$refs.contentRef){
            const contentImages = this.$refs.contentRef.querySelectorAll("img");
            contentImages.forEach((img) => {
                img.style.maxWidth = "100%";
            });
        }
    },
    methods: {
        // 修改点位的大小比例
        changePointScale(newScale){
            this.pointScale = newScale
        },
        enlarge(){
            this.$refs.dragZoom.enlarge()
        },
        ensmall(){
            this.$refs.dragZoom.ensmall()
        },
                //     this.preloader.preload(img.url, (err) => {
        //         if (!err) {
        //             this.images[i].loaded = true // 标记图片已加载
        //         }
        //     })
        // 加载图片
        loadImage() {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', this.private_map, true);
            xhr.responseType = 'blob';
            xhr.onloadstart = () => {
                this.showProgress = true;
            };
            xhr.onprogress = (event) => {
                if (event.lengthComputable) {
                    let progress = Math.round((event.loaded / event.total) * 100);
                    this.progress = progress
                }
            };
            xhr.onload = () => {
                this.showBg = true
                this.showProgress = false;
                this.imgUrl.url = URL.createObjectURL(xhr.response);
            };
            xhr.send();
        },
        // 图例展开收起
        toggleCollapse() {
            this.isCollapse = !this.isCollapse;
            // this.collapseTitle = this.isCollapse ? "收起" : '展开'
        },
        // 关闭弹窗
        closeTooltip(){
            this.showTooltip = false
            this.tooltipContent = {}
        },
        // 点位点击
        pointClick(item){
            this.tooltipContent = item
            // console.log(item.pos_x, item.pos_y)
            // console.log('x4', item.pos_x * 4, item.pos_y * 4)
            this.showTooltip = true
        },
        // 图片加载成功
        // mapLoad(){
        //     this.showBg = true
        //     this.showProgress = false;
        // },
        // 密码确认
        confirmBtn(){
            this.verifyPassword()
        },
        // 密码取消
        cancelBtn(){
            this.$router.go(-1);
        },
        // 密码验证
        async verifyPassword(){
            try {
                const { code, data } = await verifyPass({pass: this.pass});
                this.$store.dispatch('setPass', {pass: this.pass})
                this.private_map = process.env.VUE_APP_API + data
                this.showDialog = false
                this.loadImage()
            } catch (e) {
                this.showDialog = true
            }
        },
        // 获取项目
        async projects(){
            try {
                const { code, data } = await projectPageList({page_size: 200});
                // 过滤坐标点为空数据及处理数据
                data.data.forEach((item, index) => {
                    item.color = this.pointColor[item.status - 1].color
                    if(item.pos_x > 0 || item.pos_y > 0) {
                        const res = this.pointHandle(item.pos_x, item.pos_y)
                        item.position = [res[0], res[1]]
                        this.points.push(item)
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        // 点位图例展示
        showPoint(index){
            if (this.showPoints.includes(index)) {
                // 去除 str
                this.showPoints = this.showPoints.filter(item => item !== index);
            } else {
                // 加入 str
                this.showPoints = [...this.showPoints, index]
            }
        },
        // 屏幕宽度匹配坐标点
        pointHandle(x, y){
            let screenWidth = window.innerWidth
            let scale = screenWidth / 375
            x = x * scale
            y = y * scale
            return [x, y]
        }

    },
    mounted() {
    },
    created(){
        let pass = this.$store.state.pass
        if(pass) {
            this.showDialog = false
            this.pass = pass
            this.verifyPassword()
        }
        this.projects()
    },
}
</script>
<style>

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    /* touch-action: none; */
}

.map-container {
    position: absolute;
    top: 0;
    left: 0;
    /* height: 100%; */
   
}

.point-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.point-img{
    width: 30px;
    height: 30px;
}

.point-icon{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px yellow;
    opacity: 0.8;
}

.psssword-input{
    text-align: center;
}
.address-legend{
    position: fixed;
    top: 50px;
    right: 20px;
    display: flex;
    flex-direction: column;
}
.point-legend{
    position: fixed;
    top: 50px;
    left: 20px;
    display: flex;
    flex-direction: column;
}
.point-li{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.point-li-icon{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}
.point-li-text{
    font-size: 18px;
}
.address-module{
    padding: 0.625rem;
    border-bottom: 1px solid #ccc;
    width: 80px;
}
.address-btn{
    height: 18px;
    opacity: 0.7;
}
.address-text{
    font-size: 12px;
    margin-top: 6px;
    padding: 3px;
}
.tooltip{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    background: #fafafa;
    border-radius: 10px;
    width: 300px;
    height: 280px;
    overflow-y: auto;
    font-size: 16px;
    color: #333;
    padding: 10px;
    box-shadow: 0 0 3px black;
}
.site-tooltip-content{
    display: flex;
    margin-bottom: 8px;
}
.title-value{
     flex: 2;
}
.title-text{
    flex: 1.2;
    color: #5396FF;
}
.title-icon{
    width: 15px;
    height: 15px;
    /* margin-right: 5px; */
    vertical-align: top;
}
.close-tooltip{
    position: absolute;
    right: 8px;
    top: 0;
    font-size: 18px;
    color: #333;
}
.img-loading{
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.collapse-header {
    padding: 10px;
    cursor: pointer;
    background-color: #9fbaff;
    color: #333;
    text-align: center;
    position: relative;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #666;
    transition: transform 0.2s ease-in-out;
  }
  
  .arrow.down {
    transform: translateY(-50%) rotate(180deg);
  }
  
  .collapse-content {
    background-color: rgba(220,250,240,0.9);
    border-top: none;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
  
  .collapse-content.show {
    max-height: 500px;
    transition: all 0.2s ease-in-out;
  }
  .tooltip-box{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .plus_btn{
    position: fixed;
    right: 80px;
    bottom: 50px;
    z-index: 99999999;
    width: 40px;
    height: 40px;
    }
    .sub_btn{
    position: fixed;
    right: 40px;
    bottom: 50px;
    z-index: 99999999;
    width: 40px;
    height: 40px;
    }
    .mapImg{
        width: 100%;
        -webkit-image-rendering: -webkit-optimize-contrast; /* Safari */
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: pixelated; /* Chrome, Opera */
    }
    .tooltip-html{
        max-width: 100%;
        overflow-x: hidden;
    }
</style>